import appConfig from '@/shared/appConfig'
// import axios from 'axios'
import jwtInterceptor from '@/shared/jwtInterceptor'

const BASE_ENDPOINT = `${appConfig.BASE_HOST}/api/DmVungKinhTe`

const getAll = async () => {
  const response = await jwtInterceptor.get(`${BASE_ENDPOINT}/options`).catch(() => {
    return []
  })
  if (response && response.data && !response.data.error) {
    return response.data.obj
  }
  return []
}

export const vungKinhTeService = {
  getAll
}
