<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <CCardHeader tag="div">
          <CIcon name="cil-grid"/>
          Cập nhật tỉnh/thành phố
        </CCardHeader>
        <CCardBody style="min-height: 300px">
          <CForm>
            <CRow>
              <CCol lg="6">
                <CInput label="Tên tỉnh/thành phố" horizontal :value.sync="detailItem.ten"/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CSelect label="Vùng kinh tế" horizontal :options="optionsVungKinhTe"
                         :value.sync="detailItem.vungKinhTe_Ma"/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow>
                  <CCol tag="label" sm="3" class="col-form-label">Trạng thái</CCol>
                  <CCol sm="9" class="form-inline">
                    <CInputCheckbox label="Hoạt động" :checked.sync="detailItem.trangThai"/>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CForm>
          <CElementCover v-if="isLoading"/>
        </CCardBody>
        <CCardFooter tag="div" class="text-right">
          <CButton color="secondary" class="mr-2" @click="cancel">Hủy bỏ</CButton>
          <CButton color="primary" @click="saveItem" :disabled="isSaving">Lưu lại</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { statuses, loaiDonVis } from '@/shared/appConstants'
import { mapGetters, mapActions } from 'vuex'
import { vungKinhTeService } from '@/services/vung-kinh-te.service'
import { GET_TINHTHANHPHO, UPDATE_TINHTHANHPHO } from '@/store/modules/tinh-thanh-pho/actionTypes'

export default {
  name: 'TinhThanhPhoUpdate',
  data () {
    return {
      optionsTrangThai: statuses,
      optionsLoaiDonVi: loaiDonVis,
      optionsVungKinhTe: [],
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('tinhThanhPho', {
      isSaving: 'isSaving',
      responseResult: 'responseResult',
      detailItem: 'detailItem'
    })
  },
  methods: {
    ...mapActions('tinhThanhPho', {
      getItem: GET_TINHTHANHPHO,
      updateItem: UPDATE_TINHTHANHPHO
    }),
    async saveItem () {
      const data = {
        id: this.detailItem.id,
        ten: this.detailItem.ten,
        ma: this.detailItem.ma,
        vungKinhTe: Number(this.detailItem.vungKinhTe_Ma),
        trangThai: this.detailItem.trangThai
      }
      await this.updateItem(data)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        await this.$router.push({ path: '/danh-muc/tinh-thanh-pho' })
      }
    },
    cancel () {
      this.$router.push({ path: '/danh-muc/tinh-thanh-pho' })
    }
  },
  async mounted () {
    this.isLoading = true
    await this.getItem(this.$route.params.id)
    this.optionsVungKinhTe = await vungKinhTeService.getAll()
    this.isLoading = false
  }
}
</script>
